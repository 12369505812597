import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Container from "../../../components/Container/Container"
import Title from "../../../components/Title/Title"
import Button from "../../../components/Button/Button"

const Hero = () => {
  const { prismicGalleryVideo } = useStaticQuery(graphql`
    query {
      prismicGalleryVideo {
        data {
          page_pre_title {
            text
          }
          page_title {
            text
          }
          page_intro {
            html
          }
        }
      }
    }
  `)

  const galleryData = prismicGalleryVideo.data

  return (
    <>
      <Container>
        <div className="flex flex-col items-center text-center">
          <div>
            <Title preTitle>{galleryData.page_pre_title.text}</Title>
          </div>

          <div className="sm:mr-[-16px]">
            <Title singlePage>{galleryData.page_title.text}</Title>
          </div>

          <div className="pt-3 sm:pt-7 max-w-[580px]">
            <div
              dangerouslySetInnerHTML={{ __html: galleryData.page_intro.html }}
            />
          </div>
          <div className="pt-12">
            <Button arrowRight linkTo="/booking">
              VIEW AVAILABILITY
            </Button>
          </div>
        </div>
      </Container>
    </>
  )
}

export default Hero
