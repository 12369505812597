import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Container from "../../../components/Container/Container"

const PhotoGrid = () => {
  const { prismicGalleryVideo } = useStaticQuery(graphql`
    query {
      prismicGalleryVideo {
        data {
          video_group {
            video_embed_url {
              embed_url
              html
            }
          }
        }
      }
    }
  `)

  const galleryData = prismicGalleryVideo.data
  const gridItems = galleryData.video_group

  return (
    <>
      <Container className="!pt-5">
        <div className="grid gap-6 md:grid-cols-2 md:gap-8 lg:gap-10">
          {gridItems.map(({ video_embed_url }, index) => {
            return (
              <>
                <div key={index}>
                  <div
                    className="aspect-w-16 aspect-h-9"
                    dangerouslySetInnerHTML={{ __html: video_embed_url.html }}
                  />
                </div>
              </>
            )
          })}
        </div>
      </Container>
    </>
  )
}

export default PhotoGrid
